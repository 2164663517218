*,
::after,
::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color-scheme: light dark;
  color: black;
}

@media (prefers-color-scheme: dark) {
  body {
    color: white;
    background: #222;
  }
}

a {
  color: dodgerblue;
}

.centered {
  display: flex;
  justify-content: center;
}

.stack > * + * {
  margin-top: 1em !important;
}

.page-wrap {
  max-width: 450px;
  margin: 0 auto;
  padding: 0 1em;
}

.heading {
  margin: 0;
  padding: 0;
  font-weight: 1000;
}

.paragraph {
  color: hsla(0, 0%, 0%, 0.6);
}

@media (prefers-color-scheme: dark) {
  .paragraph {
    color: hsla(0, 0%, 100%, 0.6);
  }
}

.button {
  margin: 0;
  background: hsl(210, 93%, 62%);
  border: none;
  border-radius: 14px;
  padding: 1em;
  color: white;
  font-weight: 600;
  width: 100%;
  font-size: 16px;
}

.button:active {
  background: hsl(210, 93%, 52%);
}

.button.mod-secondary {
  background: hsla(0, 0%, 0%, 0.1);
  color: #469ff8;
}
.button.mod-secondary:active {
  background: hsla(0, 0%, 0%, 0.2);
}

@media (prefers-color-scheme: dark) {
  .button.mod-secondary {
    background: hsla(0, 0%, 100%, 0.1);
  }

  .button.mod-secondary:active {
    background: hsla(0, 0%, 100%, 0.2);
  }
}

.input {
  background: hsla(0, 0%, 0%, 0.1);
  border: none;
  border-radius: 14px;
  padding: 1em;
  margin: 0;
  width: 100%;
  font-size: 16px;
}

@media (prefers-color-scheme: dark) {
  .input {
    background: hsla(0, 0%, 100%, 0.1);
  }
}

.list {
  padding: 0;
  background: hsla(0, 0%, 0%, 0.05);
  border-radius: 14px;
  list-style: none;
  overflow: hidden;
  margin: 0;
}

@media (prefers-color-scheme: dark) {
  .list {
    background: hsla(0, 0%, 100%, 0.1);
  }
}

.list-item:not(:last-child)::after {
  content: '';
  display: block;
  border-top: 1px solid hsla(0, 0%, 0%, 0.1);
  width: calc(100% - 30px);
  margin: 0 auto;
}

@media (prefers-color-scheme: dark) {
  .list-item:not(:last-child)::after {
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
  }
}

.player-list-item {
  padding: 1em;
}

.silhouette-icon {
  font-size: 10em;
  margin: 0;
  filter: contrast(0) brightness(160%);
}

@media (prefers-color-scheme: dark) {
  .silhouette-icon {
    filter: contrast(0) brightness(40%);
  }
}

.typography.mod-small {
  font-size: 0.8em;
}

.typography.mod-muted {
  color: hsla(0, 0%, 0%, 0.5);
}

@media (prefers-color-scheme: dark) {
  .typography.mod-muted {
    color: hsla(0, 0%, 100%, 0.7);
  }
}

.winner-highlight {
  background: gold;
}

@media (prefers-color-scheme: dark) {
  .winner-highlight {
    background: orange;
  }
}
